import api from "@/axios";

export default class FormaPgtoService {

    getFormaPgtos() {
        return api.get('api/ssformapgtoindex' ).then((res) => res.data);
    }

    getFormaPgto(id) {
        const params = { id }
        return api.get('api/ssformapgtoget', {params}).then((res) => res.data);
    }

    saveFormaPgto(formapgto) {
        return api.post('api/ssformapgtosave', {formapgto} ).then((res) => res.data);
    }

    deleteFormaPgto(id) {
        const params = { id }
        return api.get('api/ssformapgtodel', { params }).then((res) => res.data);
    }

}
